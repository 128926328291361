import { UserStore } from '@/stores/UserStore';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Main from '../views/Main.vue';
import { pageview } from 'vue-gtag'
import { nextTick } from 'vue';

const routes: Array<RouteRecordRaw> = [
  {
    meta: { title: "Animatch - Start" },
    path: '',
    redirect: '/main/feed'
  },
  {
    meta: { title: "Animatch - Login" },
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    meta: { title: "Animatch - Create user", requiresAuth: true },
    path: '/create-user',
    component: () => import('@/views/CreateUserProfile.vue'),
  },
  {
    meta: { title: "Animatch - Add animal", requiresAuth: true },
    path: '/edit-user-profile',
    component: () => import('@/views/EditUserProfile.vue'),
  },
  {
    meta: { title: "Animatch - Add animal", requiresAuth: true },
    path: '/add-animal',
    component: () => import('@/views/AddAnimal.vue'),
  },
  {
    meta: { title: "Animatch - Edit animal", requiresAuth: true },
    path: '/edit-animal/:id',
    component: () => import('@/views/EditAnimal.vue'),
  },
  {
    meta: { title: "Animatch - Edit animal images", requiresAuth: true },
    path: '/edit-animal-images/:id',
    component: () => import('@/views/EditAnimalImages.vue'),
  },
  {
    meta: { title: "Animatch - Animal profile" },
    path: '/animal-profile/:id',
    component: () => import('@/views/AnimalProfile.vue'),
  },
  {
    meta: { title: "Animatch - Conversation", requiresAuth: true },
    path: '/conversation/:id',
    component: () => import('@/views/Conversation.vue'),
  },
  {
    path: '/main/',
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        meta: { title: "Animatch - Start" },
        path: '',
        redirect: '/main/feed',
      },
      {
        path: 'profile/:id',
        component: () => import('@/views/UserProfile.vue'),
        meta: { title: "Animatch - Profile", isNotMyUser: true }
      },
      {
        meta: { title: "Animatch - My profile" },
        path: 'my-profile',
        component: () => import('@/views/MyUserProfile.vue'),
      },
      {
        meta: { title: "Animatch - Matches" },
        path: 'matches',
        component: () => import('@/views/Matches.vue'),
      },
      {
        meta: { title: "Animatch - Conversations" },
        path: 'conversations',
        component: () => import('@/views/Conversations.vue'),
      },

      {
        meta: { title: "Animatch - Browse" },
        path: 'browse',
        component: () => import('@/views/Browse.vue'),
      },
      {
        meta: { title: "Animatch - Feed" },
        path: 'feed',
        component: () => import('@/views/GlobalFeed.vue'),
      },
      {
        meta: { title: "Animatch - Post" },
        path: 'post/:id',
        component: () => import('@/views/Post.vue'),
      },
      {
        meta: { title: "Animatch - Animal profile" },
        path: 'animal-profile/:id',
        component: () => import('@/views/AnimalProfile.vue'),
      },
      {
        meta: { title: "Animatch - Follower info" },
        path: 'follower-info/:id',
        component: () => import('@/views/FollowerInfo.vue'),
      },
      {
        meta: { title: "Animatch - Group" },
        path: 'community/:id',
        component: () => import('@/views/Community.vue'),
      },
      {
        meta: { title: "Animatch - Group members" },
        path: 'community/:id/members',
        component: () => import('@/views/CommunityMembersInfo.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  //redirect alternative domains to app.animatch.se (applies to production domains)
  if (window.location &&
    (window.location.host === "animatch-bdd0b.web.app" || window.location.host === "animatch-bdd0b.firebaseapp.com")) {
    window.location.assign(window.location.href.replace(window.location.host, "app.animatch.se"));
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isNotMyUser = to.matched.some(record => record.meta.isNotMyUser);
  const loggedInUser = await UserStore.instance.getCurrentUser()
  if (requiresAuth && !loggedInUser) {
    next('/login');
  } else if (isNotMyUser && loggedInUser?.uid === to.params.id) {
    next('/main/my-profile');
  } else {
    next();
  }
});

router.afterEach((to) => {
  // const hubspotConversations = (window as any).HubSpotConversations;
  // if (hubspotConversations != null) {
  //   const widgetStatus = hubspotConversations.widget.status();
  //   if (!widgetStatus.loaded) {
  //     hubspotConversations.resetAndReloadWidget();
  //   }
  //   hubspotConversations.widget.refresh();
  // }
  nextTick().then(() => {
    document.title = (to.meta?.title || 'Animatch') as string;
    if (process.env.VUE_APP_environment === 'Production') {
      const info = { page_path: location.pathname, page_title: document.title.replace('Animatch - ', ''), page_location: location.href }
      pageview(info)
    } else {
      console.log("Tracking disabled in developent and staging environments");
    }
  })
});

export default router
