
import { defineComponent, PropType, reactive } from "vue";
import { PostStore } from "@/stores/PostStore";
import { GlobalFeedStore } from "@/stores/FeedStore";
import { UserStore } from "@/stores/UserStore";
import { IonToggle, IonAvatar, IonTextarea, modalController } from "@ionic/vue";
import LoadIndicator from "../LoadIndicator.vue";
import { CommunityStore } from "@/stores/CommunityStore";

export default defineComponent({
	components: { IonToggle, IonAvatar, LoadIndicator, IonTextarea },
	props: {
		targetId: {
			type: String,
			required: true,
		},
		targetType: {
			type: String as PropType<"community" | "user" | "content">,
			required: true,
			default: "user",
		},
		animalId: {
			type: String,
		},
	},
	data() {
		return {
			isInitialized: false,
			maxNoOfFiles: 1,
			text: "",
			isLoading: false,
			animals: [] as Array<{
				name: string;
				id: string;
				tag: boolean;
				photo: string;
			}>,
			files: [] as Array<{ preview: string; file: File }>,
			// eslint-disable-next-line no-undef
			community: null as Amity.Community | null,
		};
	},
	emits: ["submit"],
	computed: {
		taggedList() {
			return this.animals
				.filter((a) => a.tag)
				.slice(0, 5)
				.map((a) => "a#" + a.id);
		},
	},
	async mounted() {
		const animals = await UserStore.instance.getUserAnimalsCollection();

		this.animals = Object.values(animals).map((a) => {
			return {
				name: a.name,
				id: a.id,
				tag: false,
				photo: a.photos[0]?.url || "",
			};
		});

		if (this.targetType === "community") {
			this.community = await CommunityStore.getCommunity(this.targetId);
		}

		this.isInitialized = true;
	},
	methods: {
		onCancel() {
			modalController.dismiss();
		},
		async onSubmit() {
			console.log("debug");
			if (this.text === "" && this.files.length === 0) {
				return;
			}

			this.isLoading = true;
			try {
				if (this.files.length > 0) {
					await this.createMediaPost();
				} else {
					await this.createTextPost();
				}

				if (this.targetType !== "user") {
					//global feed does only subscribe to user posts. So we need to reload the feed for other targets.
					var promise = new Promise<void>((resolve) => {
						setTimeout(async () => {
							await GlobalFeedStore.instance.refetchGlobalFeed(false);
							resolve();
						}, 1000);
					});
					await promise;
				}

				this.text = "";
			} finally {
				this.isLoading = false;
			}
			this.$emit("submit");
		},
		async createMediaPost() {
			const tags = this.taggedList;
			return PostStore.createImagePost(
				this.files.map((f) => f.file),
				this.targetId,
				this.targetType,
				this.text,
				tags
			);
		},
		async createTextPost() {
			const tags = this.taggedList;
			if (this.animalId) {
				return PostStore.createAnimalTextPost(
					this.animalId,
					this.targetId,
					this.text
				);
			}

			return PostStore.createTextPost(
				this.text,
				this.targetId,
				this.targetType,
				tags
			);
		},
		getPlaceHolderString(): string {
			if (this.community) {
				return this.$getString("FeedCreatePostPlaceholderInCommunity", {
					communityName: this.community.displayName,
				});
			}

			return this.$getString("FeedCreatePostPlaceholder");
		},
		isToggleDisabledForAnimal(animal: { tag: boolean; id: string }): boolean {
			if (!animal.tag && this.taggedList.length > 4) {
				return true;
			}
			return false;
		},
		onToggleAnimal(animal: { tag: boolean }) {
			animal.tag != animal.tag;
		},
		onFileButtonClick(e: Event) {
			e.preventDefault();
			document.getElementById("hidden-file-button")?.click();
		},
		onAddFile(e: any) {
			(Array.from(e.target.files) as File[]).forEach((f: File) => {
				if (this.files.length >= this.maxNoOfFiles) {
					return;
				}
				const fileInfo = reactive({
					preview: "",
					file: f,
				});
				this.files.push(fileInfo);

				if (fileInfo.file.type.indexOf("video") > -1) {
					fileInfo.preview = "assets/images/empty-dog-profile.png";
				} else {
					var reader = new FileReader();
					reader.onload = (e: any) => {
						fileInfo.preview = e.target.result as string;
					};
					reader.readAsDataURL(fileInfo.file);
				}
			});
		},
		onRemoveFile(file: File) {
			const removeIndex = this.files.findIndex((f) => f.file === file);
			if (removeIndex > -1) {
				this.files.splice(removeIndex, 1);
				this.removeFileFromFileList(removeIndex); //we need to do this since otherwise we cannot add the same file again directly after removing it.
			} else {
				console.log(removeIndex, file, this.files);
			}
		},
		removeFileFromFileList(index: number) {
			const dt = new DataTransfer();
			const input: any = document.getElementById("hidden-file-button");
			const { files } = input;

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (index !== i) {
					dt.items.add(file); // here you exclude the file. thus removing it.
				}
			}

			input.files = dt.files; // Assign the updates list
		},
	},
});
