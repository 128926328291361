
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import { FirebaseCollectionStore } from "./FirebaseCollectionStore";
import { UserProfile } from "@/types/UserProfile";
import { Animal } from "@/types/Animal";
import { ConversationStore } from "./ConversationsStore";
import { UserInfo } from "@/types/UserInfo";
import { AbuseReport } from "@/types/AbuseReport";
import { UserProfileFirebaseStore } from "./UserProfileFirebaseStore";
import { Signal } from "@/utilities/Signal";

export class UserStore {
  public static async init() {
    if (!UserStore._instance) {
      UserStore._instance = new UserStore();
    } else {
      throw (new Error('UserStore singleton should not be initialized twice'));
    }
  }
  private static _instance: UserStore;
  public static get instance() {
    if (!UserStore._instance) {
      throw (new Error('UserStore singleton not yet initialized, use init()'));
    }
    return UserStore._instance;
  }

  public loggedInUserStatusUpdated = new Signal<firebase.User | null>();

  private initialized: Promise<void>
  private currentUser?: firebase.User
  private constructor() {
    this.initialized = new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async user => {
        this.currentUser = user as firebase.User;

        if (user) {
          //logged in
          this.loggedInUserStatusUpdated.dispatch(user);
        }
        else {
          //logged out
          this.loggedInUserStatusUpdated.dispatch(null);
        }
        resolve();
      }, reject);
    });
  }

  public async getCurrentUser(expectLoggedInUser = false) {
    await this.initialized;
    if (expectLoggedInUser && !this.currentUser) {
      throw new Error("User not logged in");
    }
    return this.currentUser;
  }

  // user profile
  private cachedUserProfileStore?: UserProfileFirebaseStore;
  private async getUserProfileStore() {
    if (!this.cachedUserProfileStore) {
      const user = await this.getCurrentUser(true);
      const ref = firebase.firestore().collection("users").doc(user?.uid);
      this.cachedUserProfileStore = new UserProfileFirebaseStore(ref);
    }
    await this.cachedUserProfileStore.initialized;
    return this.cachedUserProfileStore;
  }

  public async getCurrentUserProfile() {
    const store = await this.getUserProfileStore();
    return store.data;
  }
  public async createUserProfile(user: UserProfile) {
    const store = await this.getUserProfileStore();
    await store.set(user);
  }
  public async updateUserProfile(user: UserProfile) {
    const store = await this.getUserProfileStore();
    await store.update(user);
  }
  public async addUnreadConversationsForUser(conversationId: string) {
    const store = await this.getUserProfileStore();
    const userProfile = store.data;
    if (userProfile.unreadConversations == null) {
      userProfile.unreadConversations = [];
    }

    if (userProfile.unreadConversations.filter(x => x === conversationId).length === 0) {
      userProfile.unreadConversations.push(conversationId);
      await store.update(userProfile);
    }
  }
  public async markConversationAsReadForUser(conversationId: string) {
    const store = await this.getUserProfileStore();
    const userProfile = store.data;
    if (userProfile.unreadConversations == null) {
      userProfile.unreadConversations = [];
    }

    if (userProfile.unreadConversations.filter(x => x === conversationId).length > 0) {
      userProfile.unreadConversations.forEach((value, index) => {
        if (value === conversationId) {
          userProfile.unreadConversations.splice(index, 1);
        }
      });
      await store.update(userProfile);
    }
  }

  public async reportUser(userIdToReport: string, message: string) {
    const abuseReport: AbuseReport = {
      reportedUserId: userIdToReport,
      reportedAt: firebase.firestore.Timestamp.now(),
      message: message
    };

    const reportAbuseFn = firebase.functions().httpsCallable("reportAbuse");
    await reportAbuseFn(abuseReport);
  }

  public async sendWelcomeMail() {
    const sendWelcomeMailFn = firebase.functions().httpsCallable("sendWelcomeMail");
    await sendWelcomeMailFn();
  }

  // user animals list
  private async getUserAnimalsStore(user?: string): Promise<FirebaseCollectionStore<Animal>> {
    if (!user) {
      return this.getMyUserAnimalsStore();
    }
    const collectionRef = firebase.firestore().collection("animals");
    const query = collectionRef.where('owner', "==", user).where('isRemoved', '==', false);
    const store = new FirebaseCollectionStore<Animal>(collectionRef, query);
    await store.initialized;
    return store;
  }

  private cachedAnimalsStore?: FirebaseCollectionStore<Animal>;
  public async getMyUserAnimalsStore() {
    if (!this.cachedAnimalsStore) {
      const user = (await this.getCurrentUser())?.uid as string;
      const store = await this.getUserAnimalsStore(user);
      this.cachedAnimalsStore = store;
    }
    return this.cachedAnimalsStore;
  }

  public async getUserAnimalsCollection(user?: string) { // TODO: possibly rename to getReactiveUserAnimalsCollection?
    const store = await this.getUserAnimalsStore(user);
    return store.data;
  }
  public async addUserAnimal(animal: Animal) {
    const store = await this.getUserAnimalsStore();
    const newId = await store.addItem(animal);
    return newId;
  }
  public async updateUserAnimal(animal: Animal) {
    const store = await this.getUserAnimalsStore();
    await store.updateItem(animal);
  }
  public async removeUserAnimal(animal: Animal) {
    const store = await this.getUserAnimalsStore();
    await store.removeItem(animal);
  }

  public async getUserAnimal(animalId = '') {
    const animals = await this.getUserAnimalsCollection();
    const animal = animals[animalId];
    if (animal) {
      return animal;
    } else {
      throw new Error("User does not own an animal with the id: " + animalId);
    }
  }

  // get public user info
  public async getPublicUserInfo(userId: string) {
    const ref = firebase.firestore().collection("userInfos").doc(userId);
    const dataContainer = await ref.get();
    const userInfo = dataContainer.data() as UserInfo;
    if (!userInfo) {
      throw new Error("No userinfo found for uid: " + userId)
    }
    return userInfo;
  }

  public async signOut() {
    await firebase.auth().signOut();
    this.cachedUserProfileStore?.dispose();
    this.cachedUserProfileStore = undefined;
    this.cachedAnimalsStore?.dispose();
    this.cachedAnimalsStore = undefined;
    ConversationStore.instance.onSignout();
  }
}
