import { createFile, createQuery, createVideo, getFile, runQuery } from "@amityco/ts-sdk";

export class FileStore {

  public static async getFile(fileId: string): Promise<Amity.File> {
    const query = createQuery(getFile, fileId);

    const promise = new Promise<Amity.File>((resolve, reject) => {
      runQuery(query, fileResult => {

        if (fileResult.error) {
          reject(fileResult.error);
        }

        if (fileResult.loading) {
          return;
        } else {
          if (!fileResult.data) {
            reject(new Error("File not found"));
          } else {
            resolve(fileResult.data);
          }
        }
      })
    });

    const file = await promise;

    return file;
  }

  public static async createImage(fileObject: File): Promise<Amity.File> {
    const data = new FormData();
    data.append('files', fileObject);

    const query = createQuery(createFile, data);

    return new Promise<Amity.File>((resolve, reject) => {
      runQuery(query, fileResult => {

        if (fileResult.error) {
          reject(fileResult.error);
        }

        if (fileResult.loading) {
          return;
        } else {
          if (!fileResult.data) {
            reject(new Error("Failed to upload file"));
          } else {
            const file = fileResult.data[0];
            resolve(file);
          }
        }
      })
    });
  }
  public static async createVideo(fileObject: File): Promise<Amity.File> {
    const data = new FormData();
    data.append('files', fileObject);

    const query = createQuery(createVideo, data);

    return new Promise<Amity.File>((resolve, reject) => {
      runQuery(query, fileResult => {

        if (fileResult.error) {
          reject(fileResult.error);
        }
        if (fileResult.loading) {
          return;
        } else {
          if (!fileResult.data) {
            reject(new Error("Failed to upload file"));
          } else {
            resolve(fileResult.data[0]);
          }
        }
      })
    });
  }
}