import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export function initalizeFireBase() {

  if (process.env.VUE_APP_environment == 'Production') {
    //LIVE
    const firebaseConfig = {
      apiKey: "AIzaSyA_Efgd9sAMNB8v2iSHmNsgySko4oXSPFI",
      authDomain: "app.animatch.se",
      projectId: "animatch-bdd0b",
      storageBucket: "animatch-bdd0b.appspot.com",
      messagingSenderId: "639490553609",
      appId: "1:639490553609:web:76f9a6202264089a6bc5d8",
      measurementId: "G-DWCVJ1KKGC"
    };

    firebase.initializeApp(firebaseConfig);
  } else {
    //STAGE
    const firebaseConfig = {
      apiKey: "AIzaSyB62stK_sv3LfEGb77vl9znslaz6MOauj4",
      authDomain: "animatch-65979.firebaseapp.com",
      projectId: "animatch-65979",
      storageBucket: "animatch-65979.appspot.com",
      messagingSenderId: "996504052721",
      appId: "1:996504052721:web:6bac12a7a279b210fc5a50"
    };

    firebase.initializeApp(firebaseConfig);
  }

  // const db = firebase.firestore();
  // if (location.hostname === "localhost") {
  //   db.useEmulator("localhost", 8085);
  // }

}
