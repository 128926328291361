import firebase from "firebase/app";
import "firebase/functions";
import {
  createClient,
  enableCache,
  connectClient,
  disconnectClient
} from "@amityco/ts-sdk";
import { UserStore } from "./UserStore";

export class AmityClientStore {

  public static async init(userStore: UserStore) {
    if (!AmityClientStore._instance) {
      AmityClientStore._instance = new AmityClientStore(userStore);
    } else {
      throw (new Error('AmityClientStore singleton should not be initialized twice'));
    }
  }
  private static _instance: AmityClientStore;
  public static get instance() {
    if (!AmityClientStore._instance) {
      throw (new Error('AmityClientStore singleton not yet initialized, use init()'));
    }
    return AmityClientStore._instance;
  }

  public static connectedUserId = '';
  private _connected: Promise<boolean>;

  public get connected(): Promise<boolean> {
    return this._connected;
  }

  private constructor(userStore: UserStore) {
    //create amity client
    const amityApiKey = process.env.VUE_APP_amity_api_key as string;
    createClient(amityApiKey, 'eu', 'App');
    enableCache();

    this._connected = Promise.resolve(false);

    userStore.loggedInUserStatusUpdated.add((user) => {
      if (user) {
        this._connected = this.connect(user);
      } else {
        this.disconnect();
      }
    })
  }

  public async connect(user: firebase.User): Promise<boolean> {

    //get amity token from backend
    const getAmityTokenFn = firebase.functions().httpsCallable("getAmityToken");
    const result = await getAmityTokenFn();
    const amityAuthToken = result.data as string;

    if (!user) {
      throw new Error("User not logged in");
    }

    this._connected = connectClient({
      userId: user.uid,
      displayName: user.displayName ?? 'No display name',
      authToken: amityAuthToken
    });

    AmityClientStore.connectedUserId = user.uid;

    return this._connected;
  }

  public async disconnect(): Promise<void> {
    await disconnectClient();
  }
}