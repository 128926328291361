import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonicVue, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/animatch.scss';

import '@uppy/robodog/dist/robodog.css';

import { initalizeFireBase } from './stores/FirebaseConfig';
import { UserStore } from './stores/UserStore';
import { LanguageStore, LanguageStorePlugin } from './stores/LanguageStore';
import * as languageStrings from './types/LanguageStrings.json';
import { AmityClientStore } from './stores/AmityClientStore';
import VueGtag from "vue-gtag";

LanguageStore.setData((languageStrings as any).default);
LanguageStore.setLocale("sv-SE");
initalizeFireBase();

UserStore.init();
AmityClientStore.init(UserStore.instance);

const app = createApp(App)
app.use(IonicVue);
app.use(router);

app.use(VueGtag, {
  config: {
    appName: 'Animatch app',
    id: "G-DWCVJ1KKGC"
  },
  router
});

app.use(LanguageStorePlugin);

// global components
app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-header', IonHeader);
app.component('ion-toolbar', IonToolbar);
app.component('ion-title', IonTitle);
app.component('ion-item', IonItem);
app.component('ion-list', IonList);
app.component('ion-item-divider', IonItemDivider);
app.component('ion-label', IonLabel);
app.component('ion-input', IonInput);
app.component('ion-button', IonButton);
app.component('ion-buttons', IonButtons);
app.component('ion-back-button', IonBackButton);
app.component('ion-datetime', IonDatetime);
app.component('ion-avatar', IonAvatar);

router.isReady().then(() => {
  app.mount('#app');
});