import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71190428"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "load-indicator"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner, { name: "dots" }),
          (_ctx.text)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}