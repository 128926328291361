import firebase from "firebase/app";
import { createQuery, observePosts, queryGlobalFeed, queryOptions, queryPosts, runQuery } from "@amityco/ts-sdk";
import { reactive } from "vue";
import { AmityClientStore } from "./AmityClientStore";
import { UserStore } from "./UserStore";
import { CommunityStore } from "./CommunityStore";

export interface FeedData {
  posts: Amity.Post<any>[];
  communities: Amity.Community[];
  hasMore: boolean;
  hasNewer?: boolean;
}

export class FeedStoreBase {
  public readonly currentPosts: FeedData = reactive({ posts: [], hasMore: false, communities: [] });

  protected _currentPosts: Record<string, Amity.Post<any>> = {};
  protected _feedUnsubscribers: Amity.Unsubscriber[] = [];
  protected _nextPage: Amity.PageRaw | null = null;

  protected static async ensureLoggedInAndConnected(): Promise<firebase.User> {
    const user = await UserStore.instance.getCurrentUser(true);

    if (!user) {
      throw new Error("User not logged in");
    }

    const connected = await AmityClientStore.instance.connected;
    if (!connected) {
      throw new Error("Amity client not connected");
    }

    return user;
  }

  protected startObserveFeed(targetId: string, targetType: string): Amity.Unsubscriber {
    return observePosts({ targetId: targetId, targetType: targetType },
      {
        onEvent: (action, post) => {
          if (action === 'onCreate' || action === 'onUpdate') {
            this._currentPosts[post.postId] = post;
          }
          else if (action === 'onDelete') {
            delete this._currentPosts[post.postId];
          }

          this.updateCurrentPosts();
        }
      });
  }

  protected async updateCurrentPosts(observeCommunityFeeds = true): Promise<void> {
    this.currentPosts.posts = this.getCurrentPostsAsArray().sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
    this.currentPosts.hasMore = this._nextPage !== null;

    //communities
    const allCommunityIds: string[] = this.currentPosts.posts
      .filter(x => x.targetType === "community")
      .map(x => x.targetId);

    const alreadyFetchedCommunityIds = this.currentPosts.communities.map(x => x.communityId);
    const communityIdsToFetch = allCommunityIds.filter(x => !alreadyFetchedCommunityIds.includes(x));

    let fetchedCommunities: Amity.Community[] = [];
    if (communityIdsToFetch.length > 0) {
      fetchedCommunities = await CommunityStore.getCommunities(communityIdsToFetch);
    }

    if (observeCommunityFeeds) {
      //subscribe to changes in communities
      fetchedCommunities.forEach(x => this._feedUnsubscribers.push(this.startObserveFeed(x.communityId, "community")));
    }

    const communityIdsToRemove = alreadyFetchedCommunityIds.filter(x => !allCommunityIds.includes(x));
    const alreadyFetchedCommunitiesToKeep = this.currentPosts.communities.filter(x => !communityIdsToRemove.includes(x.communityId));
    this.currentPosts.communities = alreadyFetchedCommunitiesToKeep.concat(fetchedCommunities);

  }

  private getCurrentPostsAsArray = () => {
    return Object.keys(this._currentPosts).map((key) => this._currentPosts[key]);
  }

  protected toDictionary(posts: Amity.Post<any>[]): Record<string, Amity.Post<any>> {
    return Object.assign({}, ...posts.map(x => ({ [x.postId]: x })));
  }

  protected static toPage(rawPage: Amity.PageRaw): Amity.Page | undefined {

    const before = rawPage.before ? parseInt(rawPage.before) : undefined;
    const after = rawPage.after ? parseInt(rawPage.after) : undefined;

    if (before) {
      return { limit: rawPage.limit, before: before };
    }

    if (after) {
      return { limit: rawPage.limit, after: after };
    }

    return { limit: rawPage.limit };
  }

  public get haveMorePosts(): boolean {
    return this.currentPosts.hasMore;
  }

  public async stopFeed(): Promise<void> {
    this._feedUnsubscribers.every(x => x());
    this._feedUnsubscribers = [];

    this._currentPosts = {};
    this.updateCurrentPosts();
    this._nextPage = null;
  }
}

export class GlobalFeedStore extends FeedStoreBase {
  private static _instance = new GlobalFeedStore();
  public static get instance() {
    return GlobalFeedStore._instance;
  }

  private constructor() {
    super();
    // private
  }

  private _feedWatcher: NodeJS.Timer | null = null;

  public async startGlobalFeed(): Promise<void> {

    const user = await GlobalFeedStore.ensureLoggedInAndConnected();

    //if we are subscribed to a global feed, unsubscribe
    if (this._feedUnsubscribers.length > 0) {
      this._feedUnsubscribers.every(x => x());
      this._feedUnsubscribers = [];
    }

    await this.refetchGlobalFeed(false);

    //start subscribing to changes for the user feed so that new posts that the user itself creates pops up in the feed.
    this._feedUnsubscribers.push(this.startObserveFeed(user.uid, 'user'));

    this._feedWatcher = setInterval(async () => {
      await this.updateHaveNewPosts();
    }, 30 * 1000);
  }

  public async refetchGlobalFeed(ensureConnected = true): Promise<void> {
    if (ensureConnected) {
      await GlobalFeedStore.ensureLoggedInAndConnected();
    }
    await this.fetchGlobalFeed({ limit: 10 }, true);
    await this.updateCurrentPosts();
    this.currentPosts.hasNewer = false;
  }

  private async fetchGlobalFeed(page: Amity.PageRaw = { limit: 10 }, reset = true): Promise<void> {
    const globalFeedQuery = createQuery(queryGlobalFeed, { page: GlobalFeedStore.toPage(page) });
    let options = queryOptions('cache_then_server', 60 * 1000);
    if (reset) {
      options = queryOptions('server_only');
    }

    return new Promise<void>((resolve, reject) => {
      runQuery(globalFeedQuery, ({ data: posts, ...metadata }) => {

        if (metadata.error) {
          reject(metadata.error);
          return;
        }

        if (metadata.loading) {
          return;
        }

        if (posts) {
          if (reset) {
            this._currentPosts = this.toDictionary(posts);
          } else {
            const result = this.toDictionary(posts);
            this._currentPosts = { ...this._currentPosts, ...result };
          }
        }
        else {
          this._currentPosts = {};
        }

        const nextPage = (metadata as any).nextPage; //don't know why I need to cast this to any to access metadata...
        this._nextPage = nextPage ? nextPage : null;
        resolve();

      }, options);
    });
  }

  private async updateHaveNewPosts(): Promise<void> {

    if (this.currentPosts.hasNewer) {
      //we have already determined that there are newer posts, so don't bother checking again
      return;
    }

    const globalFeedQuery = createQuery(queryGlobalFeed, { page: GlobalFeedStore.toPage({ limit: 1 }) });
    const options = queryOptions('server_only');

    return new Promise<void>((resolve, reject) => {
      runQuery(globalFeedQuery, ({ data: posts, ...metadata }) => {

        if (metadata.error) {
          reject(metadata.error);
          return;
        }

        if (metadata.loading) {
          return;
        }

        if (posts && posts[0]?.postId != this.currentPosts.posts[0]?.postId) {
          //we have atleast one new post
          this.currentPosts.hasNewer = true;
        }

        resolve();

      }, options);
    });
  }

  public async loadMore(): Promise<void> {
    if (this._nextPage) {
      await this.fetchGlobalFeed(this._nextPage, false);
      await this.updateCurrentPosts();
    }
  }

  public async stopFeed(): Promise<void> {
    await super.stopFeed();
    if (this._feedWatcher) {
      clearInterval(this._feedWatcher);
    }
  }

}

export class FeedStore extends FeedStoreBase {

  private static _instance = new FeedStore();
  public static get instance() {
    return FeedStore._instance;
  }

  private constructor() {
    super();
    // private
  }

  private _targetId = '';
  private _targetType = '';

  public async startFeed(targetId: string, targetType: string, filterTags: string[] | undefined = undefined): Promise<void> {

    this._targetId = targetId;
    this._targetType = targetType;
    await FeedStore.ensureLoggedInAndConnected();

    //if we are subscribed to a feed, unsubscribe
    if (this._feedUnsubscribers.length > 0) {
      this._feedUnsubscribers.every(x => x());
      this._feedUnsubscribers = [];
    }

    await this.refetchFeed(false, filterTags);

    //start subscribing to changes for the user feed so that new posts that the user itself creates pops up in the feed.
    this._feedUnsubscribers.push(this.startObserveFeed(this._targetId, this._targetType));
  }

  public async refetchFeed(ensureConnected = true, filterTags: string[] | undefined = undefined): Promise<void> {
    if (ensureConnected) {
      await FeedStore.ensureLoggedInAndConnected();
    }
    await this.fetchFeed(this._targetId, this._targetType, { limit: 10 }, true, filterTags);
    await this.updateCurrentPosts(this._targetType !== 'community');
    this.currentPosts.hasNewer = false;
  }

  public async loadMore(filterTags: string[] | undefined = undefined): Promise<void> {
    if (this._nextPage) {
      await this.fetchFeed(this._targetId, this._targetType, this._nextPage, false, filterTags);
      await this.updateCurrentPosts(this._targetType !== 'community');
    }
  }

  private async fetchFeed(targetId: string, targetType: string, page: Amity.PageRaw = { limit: 10 }, reset = true,
    filterTags: string[] | undefined = undefined): Promise<void> {
    const queryArgs: any = {
      targetId: targetId,
      targetType: targetType,
      sortBy: 'lastCreated',
      feedType: 'published',
      isDeleted: false,
      page: page
    };

    if (filterTags && filterTags.length > 0) {
      //need to encode tags here since that is apparently not done when constructing the url in the Amity SDK
      queryArgs.tags = filterTags.map(x => encodeURIComponent(x));
    }

    const feedQuery = createQuery(queryPosts, queryArgs);

    let options = queryOptions('cache_then_server', 60 * 1000);
    if (reset) {
      options = queryOptions('server_only');
    }

    return new Promise<void>((resolve, reject) => {
      runQuery(feedQuery, ({ data: posts, ...metadata }) => {

        if (metadata.error) {
          reject(metadata.error);
          return;
        }

        if (metadata.loading) {
          return;
        }

        if (posts) {
          if (reset) {
            this._currentPosts = this.toDictionary(posts);
          } else {
            const result = this.toDictionary(posts);
            this._currentPosts = { ...this._currentPosts, ...result };
          }
        } else {
          this._currentPosts = {};
        }

        const nextPage = (metadata as any).nextPage; //don't know why I need to cast this to any to access metadata...
        this._nextPage = nextPage ? nextPage : null;
        resolve();

      }, options);
    });
  }
}