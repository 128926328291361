import { UrlParameterManager } from '../utilities/UrlParameterManager';

export class LanguageStore {
  public static isInited = false;

  public static setData(strings: { [id: string]: string[] }) {
    LanguageStore.languageStrings = strings;
    this.isInited = true;

    // set initial locale
    const locale = UrlParameterManager.getParams().locale || localStorage.getItem("locale") as string;
    if (locale) {
      this.setLocale(locale);
    } else {
      this.setLocale();
    }
  }

  public static languageStrings: { [id: string]: string[] } = {};

  public static setLocale(locale = "en-US") {
    let localeIndex = LanguageStore.getLocaleIndex(locale);
    if (localeIndex === -1) {
      locale = "en-US";
      localeIndex = LanguageStore.getLocaleIndex(locale);
    }
    LanguageStore.localeString = locale;
    LanguageStore.localeIndex = localeIndex;
    localStorage.setItem("locale", locale);
  }

  public static getLocale() {
    return LanguageStore.localeString;
  }

  public static getLocaleIndex(locale: string) {
    return LanguageStore.languageStrings.locales.indexOf(locale);
  }

  public static getAvailableLocales() {
    const locales = LanguageStore.languageStrings.locales;
    const supportlist = ['en-US', 'sv-SE'];
    if (UrlParameterManager.getParams().alllocales) {
      return locales;
    }
    return locales.filter((l) => supportlist.indexOf(l) > -1);
  }

  public static getString(id = '', interpolations: { [id: string]: string } | null = null): string {
    if (UrlParameterManager.getParams().showstringids === 'true') {
      return id;
    }
    const strings = LanguageStore.languageStrings[id];
    const localeIndex = LanguageStore.localeIndex;
    if (strings) {
      if (LanguageStore.localeIndex < strings.length && strings[localeIndex] != null) {
        let langString = strings[localeIndex];
        if (langString === 'EMPTY') {
          return '';
        }
        if (interpolations) {
          Object.keys(interpolations).forEach((id) => {
            langString = langString.replace('{{' + id + '}}', interpolations[id]);
          });
        }
        return langString;
      } else {
        return "#" + strings[0];
      }
    }
    console.log("MISSING STRING", id);
    return 'MISSING:' + id;
  }
  private static localeIndex = 0;
  private static localeString = 'en-US';
}

export const LanguageStorePlugin = {
  install: (app: any, options: any) => {
    app.config.globalProperties.$getString = LanguageStore.getString;
    app.provide('LanguageStore', options);
  }
}

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $getString: (id: string, interpolations?: { [id: string]: string } | null) => string;
  }
}
