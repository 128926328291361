
import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/vue";
import { defineComponent } from "vue";
import { FullScreenLoaderManager } from "@/utilities/FullScreenLoaderManager";

export default defineComponent({
	data() {
		return {
			loadCounter: FullScreenLoaderManager.instance.getCounter(),
		};
	},
	components: {
		IonApp,
		IonRouterOutlet,
		IonLoading,
	},
});
