import { ConversationMessage } from "./ConversationMessage";
import { ConversationParticipantInfo } from "./ConversationParticipantInfo";
import { DocumentBase } from "./DocumentBase";

export enum ConversationState {
  Active = "Active",
  Closed = "Closed"
}

export interface Conversation extends DocumentBase {
  id: string;
  isRemoved: boolean;
  participants: { [userId: string]: ConversationParticipantInfo };
  lastMessage?: ConversationMessage;
  state: ConversationState;
}

