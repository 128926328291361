import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa9292d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-compose-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_load_indicator = _resolveComponent("load-indicator")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { class: "ion-no-border header-no-logo" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$getString("GeneralCancel")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$getString("FeedCreatePostTitle")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                (!_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      onClick: _ctx.onSubmit
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$getString("FeedCreatePostSendButton")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_load_indicator, { loaded: _ctx.isInitialized }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_textarea, {
                    disabled: _ctx.isLoading,
                    placeholder: _ctx.getPlaceHolderString(),
                    rows: "5",
                    type: "text",
                    modelValue: _ctx.text,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event))
                  }, null, 8, ["disabled", "placeholder", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "file-preview-container" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (fileInfo, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "file-preview"
                    }, [
                      (fileInfo.preview)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: fileInfo.preview
                          }, null, 8, _hoisted_2))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_button, {
                        color: "dark",
                        class: "remove-button",
                        onClick: ($event: any) => (_ctx.onRemoveFile(fileInfo.file))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$getString("FeedCreatePostRemoveFile")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  (!_ctx.isLoading && _ctx.files.length < _ctx.maxNoOfFiles)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        class: "file-button",
                        fill: "clear",
                        onClick: _ctx.onFileButtonClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$getString("FeedCreatePostAddFile")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, { class: "am-divider-has-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$getString("FeedCreatePostTagAnimalsTitle")) + " (" + _toDisplayString(_ctx.taggedList.length) + "/5)", 1)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.animals, (animal) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: animal.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_avatar, { slot: "start" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: animal.photo || 'assets/images/empty-dog-profile.png'
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(animal.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_toggle, {
                      modelValue: animal.tag,
                      "onUpdate:modelValue": ($event: any) => ((animal.tag) = $event),
                      onIonChange: ($event: any) => (_ctx.onToggleAnimal(animal)),
                      disabled: _ctx.isToggleDisabledForAnimal(animal)
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onIonChange", "disabled"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    id: "hidden-file-button",
                    tabindex: "-1",
                    type: "file",
                    accept: "image/*,video/*",
                    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onAddFile && _ctx.onAddFile(...args)))
                  }, null, 32))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["loaded"])
      ]),
      _: 1
    })
  ], 64))
}