import { UserProfile } from "@/types/UserProfile";
import { UserInfo } from "@/types/UserInfo";
import { FirebaseDocumentStore } from "./FirebaseDocumentStore";
import { reactive } from "@vue/reactivity";
import firebase from "firebase/app";
import "firebase/firestore";

export class UserProfileFirebaseStore extends FirebaseDocumentStore<UserProfile>{
  protected userInfoRef!: firebase.firestore.DocumentReference;

  constructor(documentRef: firebase.firestore.DocumentReference) {
    super(documentRef);
    this.userInfoRef = firebase.firestore().collection("userInfos").doc(documentRef.id);
  }

  public async create(item: UserProfile) {
    this.data = reactive(item);
    const userInfo = this.getUserInfo(item);
    const batch = firebase.firestore().batch();
    batch.set(this.documentRef, item);
    batch.set(this.userInfoRef, userInfo);
    return await batch.commit();
  }

  public async set(item: UserProfile) {
    const userInfo = this.getUserInfo(item);
    const batch = firebase.firestore().batch();
    batch.set(this.documentRef, item);
    batch.set(this.userInfoRef, userInfo);
    return await batch.commit();
  }

  public async update(item: UserProfile) {
    const userInfo = this.getUserInfo(item);
    const batch = firebase.firestore().batch();
    batch.update(this.documentRef, item);
    batch.set(this.userInfoRef, userInfo);
    return await batch.commit();
  }

  public async remove() {
    const batch = firebase.firestore().batch();
    batch.delete(this.documentRef);
    batch.delete(this.userInfoRef);
    return await batch.commit();
  }

  protected getUserInfo(profile: UserProfile) {
    return {
      id: profile.id,
      displayName: profile.displayName,
      photo: profile.photo,
      created: profile.created,
      isRemoved: profile.isRemoved,
      description: profile.description,
      birthDate: profile.birthDate,
      breederName: profile.breederName || '',
      interests: profile.interests || []
    } as UserInfo;
  }
}