
import { defineComponent } from "vue";
import { IonSpinner } from "@ionic/vue";

export default defineComponent({
	components: { IonSpinner },
	props: {
		text: {
			type: String,
			required: false,
			default: "",
		},
		loaded: {
			type: Boolean,
			required: true,
		},
	},
});
