import { reactive } from "@vue/reactivity";
export class FullScreenLoaderManager {

  private static _instance = new FullScreenLoaderManager();
  public static get instance() {
    return FullScreenLoaderManager._instance;
  }
  private constructor() {
    //private
  }

  private loaderCounter = reactive({ value: 0 });

  public getCounter() {
    return this.loaderCounter;
  }
  public addLoader() {
    this.loaderCounter.value++;
  }
  public removeLoader() {
    this.loaderCounter.value--;
    if (this.loaderCounter.value < 0) {
      this.loaderCounter.value = 0;
      console.warn("Removing fullscreen loader that is already removed");
    }
  }
  public reset() {
    this.loaderCounter.value = 0;
  }
}