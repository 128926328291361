import { addReaction, createPost, createQuery, getPost, getPostTopic, observePost, queryOptions, removeReaction, runQuery, subscribeTopic, SubscriptionLevels } from "@amityco/ts-sdk";
import { reactive } from "vue";
import { AmityClientStore } from "./AmityClientStore";
import { FileStore } from "./FileStore";
import { UserStore } from "./UserStore";

export interface PostData {
  post?: Amity.Post<any>;
}

export class PostStore {

  private static _instance = new PostStore();
  public static get instance() {
    return PostStore._instance;
  }

  //private _postWatcher: NodeJS.Timer | null = null;

  public currentPost: PostData = reactive({});
  private _postUnsuscriber: Amity.Unsubscriber | null = null;
  private _observedPostId: string | null = null;
  private _realtimePostUnsubscriber: Amity.Unsubscriber | null = null;
  private _realtimeCommentsUnsubscriber: Amity.Unsubscriber | null = null;

  private constructor() {
    // private
  }

  public static async getPost(postId: string): Promise<Amity.Post<any>> {

    await PostStore.ensureLoggedInAndConnected();

    const query = createQuery(getPost, postId);

    const options = queryOptions('cache_then_server', 10 * 1000);

    const promise = new Promise<Amity.Post<any>>((resolve, reject) => {
      runQuery(query, postResult => {

        if (postResult.error) {
          reject(postResult.error);
        }

        if (postResult.loading) {
          return;
        } else {
          if (!postResult.data) {
            reject(new Error("Post not found"));
          } else {
            resolve(postResult.data);
          }
        }
      }, options)
    });

    return await promise;
  }

  public async getPost(postId: string, observePost = true): Promise<Amity.Post<any>> {

    const post = await PostStore.getPost(postId);

    if (observePost) {
      let shouldStartObserve = true;
      if (this._postUnsuscriber) {
        //we are observing something
        if (this._observedPostId === postId) {
          //we are observing this post
          shouldStartObserve = false;
        } else {
          this._postUnsuscriber();
        }
      }

      if (shouldStartObserve) {
        this.startObservePost(postId);
        this.updateCurrentPost();
      }
    }

    return post;
  }

  public stopObservingPost(): void {
    this._observedPostId = null;
    if (this._postUnsuscriber) {
      this._postUnsuscriber();
      this._postUnsuscriber = null;
    }
  }

  public async setupRealTimeTracking(): Promise<void> {
    if (!this.currentPost.post) {
      throw new Error("Cannot hookup real time tracking without a post");
    }

    this.removeRealTimeTracking();

    const postTopic = getPostTopic(this.currentPost.post, SubscriptionLevels.POST);
    const commentsTopic = getPostTopic(this.currentPost.post, SubscriptionLevels.COMMENT);

    const postSubscribe = new Promise<void>((resolve, reject) => {
      this._realtimePostUnsubscriber = subscribeTopic(postTopic, error => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      })
    });

    const commentsSubscribe = new Promise<void>((resolve, reject) => {
      this._realtimeCommentsUnsubscriber = subscribeTopic(commentsTopic, error => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      })
    });

    await Promise.all([postSubscribe, commentsSubscribe]);
  }

  public removeRealTimeTracking(): void {

    if (this._realtimePostUnsubscriber) {
      this._realtimePostUnsubscriber();
      this._realtimePostUnsubscriber = null;
    }

    if (this._realtimeCommentsUnsubscriber) {
      this._realtimeCommentsUnsubscriber();
      this._realtimeCommentsUnsubscriber = null;
    }
  }

  private startObservePost(postId: string): Amity.Unsubscriber {
    this._observedPostId = postId;
    return observePost(postId, postUpdate => {
      this.currentPost.post = postUpdate.data;
    });
  }

  private async updateCurrentPost(): Promise<void> {
    const postId = this._observedPostId as string;
    const post = await PostStore.getPost(postId);
    this.currentPost.post = post;
  }

  private static async ensureLoggedInAndConnected(): Promise<void> {
    const user = await UserStore.instance.getCurrentUser(true);

    if (!user) {
      throw new Error("User not logged in");
    }

    const connected = await AmityClientStore.instance.connected;
    if (!connected) {
      throw new Error("Amity client not connected");
    }
  }

  public static async createImagePost(
    files: File[],
    targetId: string,
    targetType: "community" | "user" | "content" = 'user',
    content?: string,
    tags?: string[]
  ): Promise<void> {
    if (files.length === 0) {
      throw new Error('No attached files')
    }

    const attachments: Array<{ fileId: string, type: 'image' | 'video' }> = [];
    const filePromises = files.map(f => {
      if (f.type.indexOf('image') > -1) {
        return FileStore.createImage(f);
      } else {
        return FileStore.createVideo(f);
      }
    });
    const fileRefs = await Promise.all(filePromises);
    fileRefs.forEach(f => {
      attachments.push({ fileId: f.fileId, type: f.type })
    });
    console.log('attachments', attachments)

    const query = createQuery(createPost, {
      dataType: 'text',
      attachments,
      data: {
        text: content,
      },
      targetType: targetType,
      targetId: targetId,
      tags: tags
    });

    return new Promise<void>((resolve) => {
      runQuery(query, () => resolve());
    });
  }

  public static async createTextPost(content: string, targetId: string, targetType: "community" | "user" | "content" = 'user',
    tags?: string[], metadata?: Record<string, any>): Promise<void> {
    const query = createQuery(createPost, {
      dataType: 'text',
      data: {
        text: content
      },
      targetType: targetType,
      targetId: targetId,
      tags: tags,
      metadata: metadata
    });


    return new Promise<void>((resolve) => {
      runQuery(query, () => resolve());
    });
  }

  public static async createAnimalTextPost(animalId: string, communityId: string, content: string): Promise<void> {
    return this.createTextPost(content, communityId, 'community', ['animal-post'], { animalId: animalId, communityId: communityId });
  }

  public static async likePost(postId: string): Promise<void> {
    const query = createQuery(addReaction, 'post', postId, 'like');

    return new Promise<void>((resolve) => {
      runQuery(query, () => resolve());
    });
  }

  public static async unlikePost(postId: string): Promise<void> {
    const query = createQuery(removeReaction, 'post', postId, 'like');

    const promise = new Promise<void>((resolve) => {
      runQuery(query, () => resolve());
    });

    return promise;
  }

}