
export class Misc {
  public static wait(duration: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    })
  }

  public static randomRange(min: number, max: number, returnFloat?: boolean): number {
    const value = min + Math.random() * (max - min);
    if (returnFloat) {
      return value;
    } else {
      return Math.round(value);
    }
  }

  // public static formatTimestamp(timestamp: number, locale = 'en-US', options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) {
  //   const date = new Date(timestamp);
  //   return date.toLocaleDateString(locale, options)
  // }

  public static randomItem<T>(array: T[]): T {
    return array[Misc.randomRange(0, array.length - 1)];
  }

  public static randomSplice<T>(array: T[]): T {
    return array.splice(Misc.randomRange(0, array.length - 1), 1)[0];
  }

  public static limitRange(value: number, min: number, max: number): number {
    return Math.max(Math.min(value, max), min);
  }

  public static randomizeArray(array: any[]): any[] {
    const o = array.slice(0);
    for (let j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) {
      // nothing;
    }
    return o;
  }

  public static getClientPos(e: MouseEvent | TouchEvent) {
    switch (e.type) {
      case 'mousedown':
      case 'mousemove':
      case 'mouseup':
        return {
          x: (e as MouseEvent).clientX,
          y: (e as MouseEvent).clientY
        }
      default:
        console.log(e)
        return {
          x: (e as TouchEvent).touches[0].clientX,
          y: (e as TouchEvent).touches[0].clientY
        }
    }
  }

  public static setDecimals(value = 0, decimals = 1) {
    decimals = Misc.limitRange(decimals, 0, 10);
    const roundingValue = Math.pow(10, decimals);
    return Math.round(value * roundingValue) / roundingValue;
  }

  public static mergeData<T>(target: T, newData: T): void {
    for (const iPropName in newData) {
      if (typeof (target[iPropName]) === "object") {
        Misc.mergeData(target[iPropName], newData[iPropName]);
      } else {
        target[iPropName] = newData[iPropName];
      }
    }
  }

  public static copy<T>(source: T): T {
    return JSON.parse(JSON.stringify(source));
  }

  public static capitaliseFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public static arrayInit<T>(size: number, defaultValue: T): T[] {
    const arr = [];
    let i = 0;
    while (i < size) {
      arr[i++] = defaultValue;
    }
    return arr;
  }

  public static interpolate(start: number, end: number, ratio: number) {
    return start * (1 - ratio) + end * ratio;
  }

  public static getRatioBetweenPositions(start = 0.1, end = 2.5, value = 0.5, clamped = true) {
    end = end - start;
    value = value - start;
    start = 0;
    if (clamped) {
      value = Misc.limitRange(value, start, end);
    }
    return value / end;
  }

  public static approximately(value: number, target: number, threshold = 0.0000000000005) {
    return Math.abs(value - target) < threshold;
  }

  public static approximatelyDividable(value: number, mod: number, threshold = 0.0000000000005) {
    return Math.abs(((value) % mod + mod) % mod) < threshold ||
      Math.abs(((value) % mod + mod) % mod - mod) < threshold;
  }
}



