export class UrlParameterManager {
    public static isEnabled = true;

    public static getParams(url = ''): { [key: string]: string } {
        if (url === "") {
            url = document.location.href;
        }
        const splittedURL = url.split("?");
        const params: { [key: string]: string } = {};
        if (splittedURL.length > 1) {
            const paramStrings = splittedURL[1].split("&");
            paramStrings.forEach((str) => {
                const pair = str.split("=");
                if (pair.length === 1) {
                    pair.push("");
                }
                params[pair[0].toLowerCase()] = pair[1];
            });
        }
        if (UrlParameterManager.isEnabled === false) {
            return {};
        } else {
            return params;
        }
    }

    // TODO: commented because Vue does not recognize url parameters on route change
    // public static setParams(url: string = '', params: { [key: string]: string; }) {
    //     if (url === "") {
    //         url = document.location.href;
    //     }
    //     const splittedURL = url.split("?");
    //     url = splittedURL[0] + '?';
    //     for (let p in params) {
    //         if (params.hasOwnProperty(p)) {
    //             url += p;
    //             if (params[p]) {
    //                 url += '=' + params[p];
    //             }
    //         }
    //     }
    //     history.pushState(null, '', url);
    //     return url;
    // }
}
