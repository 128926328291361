
import { FeedData, GlobalFeedStore } from "@/stores/FeedStore";
import { UserStore } from "@/stores/UserStore";
import { UserProfile } from "@/types/UserProfile";
import {
	IonTabBar,
	IonTabButton,
	IonTabs,
	// IonLabel,
	IonIcon,
	IonPage,
	IonBadge,
	IonModal,
	IonRouterOutlet,
	modalController,
} from "@ionic/vue";
import {
	personOutline,
	squareOutline,
	chatboxOutline,
	heartOutline,
	gridOutline,
	pawOutline,
	createSharp,
} from "ionicons/icons";
import { defineComponent } from "vue";
import ComposePost from "@/components/feeds/ComposePost.vue";

export default defineComponent({
	name: "Tabs",
	components: {
		// IonLabel,
		IonTabs,
		IonTabBar,
		IonTabButton,
		IonIcon,
		IonPage,
		IonModal,
		IonBadge,
		IonRouterOutlet,
		ComposePost,
	},
	data() {
		return {
			userProfile: {} as UserProfile,
			showTabs: true,
			feedData: {} as FeedData,
		};
	},
	setup() {
		return {
			gridOutline,
			personOutline,
			squareOutline,
			chatboxOutline,
			heartOutline,
			pawOutline,
			createSharp,
		};
	},
	mounted: async function () {
		this.userProfile = await UserStore.instance.getCurrentUserProfile();
		this.feedData = GlobalFeedStore.instance.currentPosts;
	},
	methods: {
		async onFeedClick() {
			await GlobalFeedStore.instance.refetchGlobalFeed(false);
		},
		onSubmitted() {
			modalController.dismiss();
		},
	},
});
