import { Conversation, ConversationState } from "@/types/Conversation";
import { FirebaseCollectionStore } from "./FirebaseCollectionStore";
import { UserStore } from "./UserStore";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { ConversationMessage } from "@/types/ConversationMessage";
import { ConversationParticipantInfo } from "@/types/ConversationParticipantInfo";

export class ConversationStore {
  private static _instance = new ConversationStore();
  public static get instance() {
    return ConversationStore._instance;
  }
  private constructor() {
    // private
  }

  public static getParticipants(c: Conversation, currentUserId: string) {
    let me: ConversationParticipantInfo | null = null;
    let other: ConversationParticipantInfo | null = null;
    for (const key in c.participants) {
      if (Object.prototype.hasOwnProperty.call(c.participants, key)) {
        c.participants[key].userId = key;
        if (key !== currentUserId) {
          other = c.participants[key];
        } else {
          me = c.participants[key];
        }
      }
    }
    if (me && other) {
      return { me, other };
    }
    throw new Error("No recipient found");
  }

  public async isConversationUnreadForUser(conversation: Conversation, userId: string): Promise<boolean> {
    const lastReadMessageId = conversation.participants[userId].lastReadMessageId;
    const messages = await this.getMessages(conversation.id);
    const nextMessageNotCreatedByUser = ConversationStore.getNextMessagePostedAfterMessageNotCreatedByUser(
      lastReadMessageId, messages, userId
    );

    const haveNotReadAnyMessageButMessagesFromOtherUserExists = lastReadMessageId == null && nextMessageNotCreatedByUser != null;

    const haveReadMessagesButDoHaveLaterUnreadMessageCreatedByOtherUser =
      lastReadMessageId != null && nextMessageNotCreatedByUser != null && lastReadMessageId !== nextMessageNotCreatedByUser.id;

    const isUnread = haveNotReadAnyMessageButMessagesFromOtherUserExists ||
      haveReadMessagesButDoHaveLaterUnreadMessageCreatedByOtherUser;

    return isUnread;
  }

  public static getLatestMessage(messages: { [messageId: string]: ConversationMessage }): ConversationMessage | null {
    let latestMessage: ConversationMessage | null = null;
    for (const messageId of Object.keys(messages)) {

      const currentMessage = messages[messageId];

      if (latestMessage == null) {
        latestMessage = currentMessage;
        continue;
      }

      if (latestMessage.sentAt.toMillis() < currentMessage.sentAt.toMillis()) {
        latestMessage = currentMessage;
      }

    }

    return latestMessage;
  }

  public static getNextMessagePostedAfterMessageNotCreatedByUser(messageId: string | null | undefined,
    messages: { [messageId: string]: ConversationMessage }, userId: string):
    ConversationMessage | null {

    let lastMessagePostedByOthers: ConversationMessage | null = null;
    for (const messageId of Object.keys(messages)) {
      const currentMessage = messages[messageId];

      if (currentMessage.sentBy !== userId) {

        if (lastMessagePostedByOthers == null) {
          lastMessagePostedByOthers = currentMessage;
          continue;
        }

        if (lastMessagePostedByOthers.sentAt.toMillis() < currentMessage.sentAt.toMillis()) {
          lastMessagePostedByOthers = currentMessage;
        }
      }
    }

    if (lastMessagePostedByOthers == null) {
      return null;
    }

    if (messageId != null) {
      const messageToCompareTo = messages[messageId];
      if (messageToCompareTo.sentAt.toMillis() < lastMessagePostedByOthers.sentAt.toMillis()) {
        return lastMessagePostedByOthers;
      } else {
        return null;
      }
    }

    return lastMessagePostedByOthers;

  }

  private cachedUserConversationsStore?: FirebaseCollectionStore<Conversation>
  public async getUserConversationsStore() {
    if (!this.cachedUserConversationsStore) {
      const user = await UserStore.instance.getCurrentUser(true);
      const collectionRef = firebase.firestore().collection("conversations");
      const query = collectionRef
        .where(`participants.${user?.uid}.userId`, "!=", "");
      this.cachedUserConversationsStore = new FirebaseCollectionStore<Conversation>(collectionRef, query);
    }
    await this.cachedUserConversationsStore.initialized;
    return this.cachedUserConversationsStore;
  }

  // conversations
  public async getUserConversations() {
    const store = await this.getUserConversationsStore();
    return store.data;
  }

  public async initiateConversation(userId: string) {
    const user = await UserStore.instance.getCurrentUserProfile();
    const participants: { [user: string]: ConversationParticipantInfo } = {};
    participants[user.id] = {
      userId: user.id,
      userPhoto: user.photo,
      userName: user.displayName,
      active: true
    };
    const otherUser = await UserStore.instance.getPublicUserInfo(userId);
    participants[otherUser.id] = {
      userName: otherUser.displayName,
      userPhoto: otherUser.photo,
      userId: otherUser.id,
      active: true
    };
    const conversation: Conversation = {
      id: "",
      participants,
      state: ConversationState.Active,
      isRemoved: false,
      created: firebase.firestore.Timestamp.now()
    }

    const initiateConversationFn = firebase.functions().httpsCallable("initiateConversation");
    const result = await initiateConversationFn(conversation);
    const conversationId = result.data as string;
    return conversationId;
  }

  public async getConversation(conversationId: string) {
    const conversations = await this.getUserConversations();
    const conversation = conversations[conversationId];
    if (conversation) {
      return conversation;
    } else {
      throw new Error("User does not have a conversation with id: " + conversationId);
    }
  }

  // conversation messages
  private cachedConversationMessages: { [conversationId: string]: FirebaseCollectionStore<ConversationMessage> } = {}
  private async getConversationMessageStore(conversationId: string) {
    if (!this.cachedConversationMessages[conversationId]) {
      const collectionRef = firebase.firestore().collection("conversations").doc(conversationId).collection("messages");
      this.cachedConversationMessages[conversationId] = new FirebaseCollectionStore<ConversationMessage>(collectionRef);
    }
    await this.cachedConversationMessages[conversationId].initialized;
    return this.cachedConversationMessages[conversationId];
  }

  public async getMessages(conversationId: string) {
    await this.getConversationMessageStore(conversationId);
    return this.cachedConversationMessages[conversationId].data;
  }

  public async postMessage(conversationId: string, message: ConversationMessage) {
    const store = await this.getConversationMessageStore(conversationId);
    const id = await store.addItem(message);
    return id;
  }

  public async setLastMessageSeen(conversationId: string, conversationMessageId: string, userId: string) {
    const store = await this.getUserConversationsStore();
    const conversation = store.getItemById(conversationId);
    if (!conversation) {
      throw new Error(`no conversation with id ${conversationId} exists`);
    }
    conversation.participants[userId].lastReadMessageId = conversationMessageId;
    await store.updateItem(conversation);
  }

  public onSignout() { // TODO: this is called from UserStore, we should probably listen for sign-out instead
    if (this.cachedUserConversationsStore) {
      this.cachedUserConversationsStore.dispose();
      this.cachedUserConversationsStore = undefined;
    }
  }
}