import firebase from "firebase/app";
import { createQuery, getCommunities, getCommunity, getCommunityTopic, joinCommunity, leaveCommunity, observeCommunity, queryCommunities, queryCommunityMembers, runQuery, subscribeTopic, SubscriptionLevels } from "@amityco/ts-sdk";
import { UserStore } from "./UserStore";
import { AmityClientStore } from "./AmityClientStore";
import { reactive } from "vue";

export interface CommunityData {
    community?: Amity.Community | undefined;
}

export class CommunityStore {

    public readonly currentCommunity: CommunityData = reactive({});
    private static _observeUnsubscriber: Amity.Unsubscriber | undefined = undefined;

    private static _instance = new CommunityStore();
    public static get instance() {
        return CommunityStore._instance;
    }

    private constructor() {
        // private
    }

    private static _realtimeUnsubscriber: Amity.Unsubscriber | null = null;

    public static async joinCommunity(communityId: string): Promise<boolean> {
        const query = createQuery(joinCommunity, communityId);

        return new Promise<boolean>((resolve, reject) => {
            runQuery(query, result => {
                if (result.error) {
                    reject(result.error);
                }

                if (result.loading) {
                    return;
                }
                resolve(result.data ?? false);
            });
        });
    }

    public static async leaveCommunity(communityId: string): Promise<boolean> {
        const query = createQuery(leaveCommunity, communityId);

        return new Promise<boolean>((resolve, reject) => {
            runQuery(query, result => {
                if (result.error) {
                    reject(result.error);
                }

                if (result.loading) {
                    return;
                }
                resolve(result.data ?? false);
            });
        });
    }

    public static async getMembers(communityId: string, page: Amity.Page = { limit: 100 }): Promise<Amity.Membership<"community">[]> {

        await this.ensureLoggedInAndConnected();

        const query = createQuery(queryCommunityMembers, {
            communityId: communityId,
            page: page
        });

        return new Promise<Amity.Membership<"community">[]>((resolve, reject) => {
            runQuery(query, result => {
                if (result.error) {
                    reject(result.error);
                }

                if (result.loading) {
                    return;
                }

                if (result.data) {
                    resolve(result.data);
                }
                else {
                    resolve([]);
                }
            })
        });
    }

    private async observeCommunity(communityId: string): Promise<void> {
        this.stopObserveCommunity();
        CommunityStore._observeUnsubscriber =
            observeCommunity(communityId, updated => {
                this.currentCommunity.community = updated.data;
            });
    }

    public async startObserveCommunity(communityId: string) {
        const community = await CommunityStore.getCommunity(communityId);
        this.currentCommunity.community = community ?? undefined;
        await this.observeCommunity(communityId);
    }

    public stopObserveCommunity() {
        if (CommunityStore._observeUnsubscriber) {
            CommunityStore._observeUnsubscriber();
            CommunityStore._observeUnsubscriber = undefined;
        }
    }

    public static async getCommunity(communityId: string): Promise<Amity.Community | null> {
        await this.ensureLoggedInAndConnected();

        return new Promise<Amity.Community | null>((resolve, reject) => {
            const query = createQuery(getCommunity, communityId);
            runQuery(query, communityResult => {
                if (communityResult.error) {
                    reject(communityResult.error);
                    return;
                }

                if (communityResult.loading) {
                    return;
                }

                if (communityResult.data) {
                    resolve(communityResult.data);
                }
                else {
                    resolve(null);
                }
            });
        });
    }

    public static async getCommunities(communityIds: string[]): Promise<Amity.Community[]> {
        return new Promise<Amity.Community[]>((resolve, reject) => {
            const query = createQuery(getCommunities, communityIds);
            runQuery(query, result => {
                if (result.error) {
                    reject(result.error);
                    return;
                }

                if (result.loading) {
                    return;
                }

                if (result.data) {
                    resolve(result.data);
                }
                else {
                    resolve([]);
                }
            })
        });
    }

    public static async getCommunityWithUniqueTag(tag: string): Promise<Amity.Community | null> {
        const getCommunityQuery = createQuery(queryCommunities, {
            tags: [tag],
            isDeleted: false
        });

        return new Promise<Amity.Community | null>((resolve, reject) => {
            runQuery(getCommunityQuery, result => {
                if (result.error) {
                    reject(result.error);
                }

                if (result.loading) {
                    return;
                }

                if (result.data) {
                    if (result.data.length > 1) {
                        //something is wrong, there should only be one matching community
                        reject(new Error(`Found more than one community with tag ${tag}`));
                    }
                    else if (result.data.length === 1) {
                        resolve(result.data[0]);
                    } else {
                        resolve(null);
                    }
                }
                else {
                    resolve(null);
                }


            });
        });
    }

    public static async listCommunities(numberOfCommunities = 100): Promise<Amity.Community[]> {
        await this.ensureLoggedInAndConnected();

        const query = createQuery(queryCommunities, {
            page: { limit: numberOfCommunities },
            isDeleted: false
        });

        return new Promise<Amity.Community[]>((resolve, reject) => {
            runQuery(query, result => {
                if (result.error) {
                    reject(result.error);
                }

                if (result.loading) {
                    return;
                }

                if (result.data) {
                    resolve(result.data);
                }
                else {
                    resolve([]);
                }
            })
        });
    }

    private static async ensureLoggedInAndConnected(): Promise<firebase.User> {
        const user = await UserStore.instance.getCurrentUser(true);

        if (!user) {
            throw new Error("User not logged in");
        }

        const connected = await AmityClientStore.instance.connected;
        if (!connected) {
            throw new Error("Amity client not connected");
        }

        return user;
    }

    public static async setupRealTimeTracking(community: Amity.Community): Promise<void> {

        if (!community.isJoined) {
            //cannot track non-joined group
            return;
        }

        this.removeRealTimeTracking();

        const postTopic = getCommunityTopic(community, SubscriptionLevels.COMMUNITY);
        await new Promise<void>((resolve) => {
            this._realtimeUnsubscriber = subscribeTopic(postTopic, error => {
                if (error) {
                    console.error(error);
                    resolve();
                    //reject(error);
                } else {
                    resolve();
                }
            })
        });
    }

    public static removeRealTimeTracking(): void {

        if (this._realtimeUnsubscriber) {
            this._realtimeUnsubscriber();
            this._realtimeUnsubscriber = null;
        }
    }
}
