export class Signal<T> {
  private listeners: Array<{ fn: (o: T) => void; once: boolean }> = [];
  private lastValue: T = null as any as T;
  public memorize = false;
  public active = true;

  public constructor(memorize = false, initialValue: T = null as any as T) {
    this.memorize = memorize;
    this.lastValue = initialValue;
  }
  public add(listener: (o: T) => void) {
    this.listeners.push({ fn: listener, once: false });
    this.autoDispatch();
  }

  public addOnce(listener: (o: T) => void) {
    this.listeners.push({ fn: listener, once: true });
    this.autoDispatch();
  }

  public autoDispatch() {
    if (this.memorize && this.lastValue !== null) {
      setTimeout(() => { // add delay to make timing consistent between memorized and regular signals
        this.dispatch(this.lastValue);
      }, 0);
    }
  }

  public remove(listener: (o: T) => void) {
    this.listeners = this.listeners.filter((l) => l.fn !== listener);
  }

  public removeAll() {
    this.listeners.length = 0;
  }

  public count() {
    return this.listeners.length;
  }

  public dispatch(o: T) {
    let triggeredOnce = false;
    this.listeners.forEach((l) => {
      l.fn.call(this, o);
      if (l.once) {
        triggeredOnce = true;
      }
    });
    if (triggeredOnce) {
      this.listeners = this.listeners.filter((l) => l.once === false);
    }
    if (this.memorize) {
      this.lastValue = o;
    }
  }

}
