import { DocumentBase } from "@/types/DocumentBase";
import { Misc } from "@/utilities/Misc";
import { reactive } from "@vue/reactivity";
import firebase from "firebase/app";
import "firebase/firestore";

export class FirebaseCollectionStore<T extends DocumentBase>{
  public data: { [id: string]: T } = reactive({});

  public initialized: Promise<void>;
  private unsubscribe!: () => void;

  constructor(
    protected collectionRef: firebase.firestore.CollectionReference,
    protected query: firebase.firestore.Query | null = null
  ) {
    let isInitialized = false;
    this.initialized = new Promise<void>((resolve) => {
      // create connection to firebase
      const ref = query || collectionRef;
      this.unsubscribe = ref.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const nodeData = change.doc.data() as T;
          if (change.type === "added") {
            // console.log("Added node: ", nodeData);
            this.applyAdd(nodeData);
          }
          if (change.type === "modified") {
            // console.log("Modified node: ", nodeData);
            this.applyChange(nodeData);
          }
          if (change.type === "removed") {
            // console.log("Removed node: ", nodeData);
            this.applyRemove(nodeData);
          }
        });
        if (!isInitialized) {
          isInitialized = true;
          resolve();
        }
      });
    });
  }

  public getCollectionRef() {
    return this.collectionRef;
  }

  public getAllItemsAsArray = () => {
    const list = Object.keys(this.data).map((key) => this.data[key]);
    return list;
  }

  public getItemById = (id: string) => {
    const item = this.data[id];
    if (item) {
      return item;
    } else {
      console.warn("No cached item with id:", id), "in collection";
    }
  }

  public async addItem(node: T) {
    const ref = this.collectionRef.doc();
    node.id = ref.id;
    await ref.set(Object.assign({}, node));
    return ref.id;
  }

  public async updateItem(node: T, skipServerUpdate = false) {
    if (skipServerUpdate) {
      return this.applyChange(Misc.copy(node));
    } else {
      return this.collectionRef.doc(node.id).update(Object.assign({}, node));
    }
  }

  public async changeMultipleItems(nodes: T[]) {
    const batch = firebase.firestore().batch();
    nodes.forEach((node) => {
      const ref = this.collectionRef.doc(node.id);
      batch.update(ref, node);
    })
    return batch.commit();
  }

  public async removeItem(node: T) {
    await this.collectionRef.doc(node.id).delete();
  }

  // updating functions after firebase change
  public applyAdd = (node: T) => {
    this.data[node.id] = node;
  }

  public applyChange = (updated: T) => {
    Misc.mergeData(this.data[updated.id], updated);
  }

  public applyRemove = (removed: T) => {
    delete this.data[removed.id];
  }

  public dispose() {
    this.unsubscribe();
  }
}
