import { DocumentBase } from "@/types/DocumentBase";
import { Misc } from "@/utilities/Misc";
import { reactive } from "@vue/reactivity";
import firebase from "firebase/app";
import "firebase/firestore";

export class FirebaseDocumentStore<T extends DocumentBase>{
  public get docId() {
    return this.documentRef.id;
  }
  public data = reactive({} as T);

  public initialized: Promise<void>;
  private unsubscribe!: () => void;

  constructor(protected documentRef: firebase.firestore.DocumentReference) {
    this.initialized = new Promise<void>((resolve) => {
      this.unsubscribe = documentRef.onSnapshot((snapshot) => {
        if (snapshot && snapshot.data()) {
          Misc.mergeData(this.data as T, snapshot.data() as T);
          //console.log("resolved data", snapshot.data());
        }
        resolve();
      });
    });
  }

  public async create(item: T) {
    this.data = reactive(item);
    await this.documentRef.set(item);
  }

  public async set(item: T) {
    await this.documentRef.set(item);
  }

  public async update(item: T) {
    await this.documentRef.update(item);
  }

  public async remove() {
    await this.documentRef.delete();
  }

  public dispose() {
    this.unsubscribe();
  }
}

